import React from 'react'
import './about.css'
import ME from '../../assets/profile.jpeg'

import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {HiOutlineOfficeBuilding} from 'react-icons/hi'

const About = () => {
  const startDate = new Date(2019, 5);
  const currentDate = new Date();
  const yearsDiff = currentDate.getFullYear() - startDate.getFullYear();
  const monthsDiff = currentDate.getMonth() - startDate.getMonth();
  const totalYears = monthsDiff >= 9 ? yearsDiff + 1 : yearsDiff;
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="about" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
          <a href="#experience"  className='text-white'><article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>Over {totalYears} Years</small>
            </article></a>

            <a href="#portfolio"  className='text-white'><article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Work</h5>
              <small>2 Clients / 3 POC's</small>
            </article></a>

            <a href="https://dpskw.com/" target="_blank" rel="noreferrer" className='text-white'><article className='about__card'>
              <HiOutlineOfficeBuilding className='about__icon'/>
              <h5>DPS Kuwait</h5>
              <small>Currently Employed</small>
            </article></a>
          </div>

        <p>
        Bringing forth expertise in design, developement, testing and maitenance
        of systems. Proficient in assortment of technologies. 
        Able to effectively self-manage
        during independent projects, as well as collaborate in a team setting. 
        </p>

        <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About