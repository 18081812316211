import React from 'react'
import './blog.css'



//array of Projects

const data = [
  {
    id: 1,
    title: 'Gogo Shell via Rest API',
    demo: 'https://liferay.dev/blogs/-/blogs/gogo-shell-via-rest-api',
    tech: 'Liferay',
    summary: 'you read it right :) , the idea might sound weird to many but if you are like me and have been working on Liferay for quiet some time especially for clients with lots of custom OSGI modules......'
  }
]

function Blog() {
  return (
    <section id='blog'>
      <h5>My Recent Contributions</h5>
      <h2>Blogs</h2>

      <div className="container blog__container">
        {
          data.map(({id, title,summary, demo,tech}) => {
            return (
              <article key={id} className='blog__item'>
               <h2>{title}</h2>
               <p>{summary}</p>
               <div className="blog__item-cta">
              <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Read More</a>
              </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Blog